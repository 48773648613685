/**
 * @define Section
 * use strict
 */

.Section {
  &--meetCoach,
  &--testimonials,
  &--pricing {
    background: linear-gradient(to top, var(--c-grey-lighter), white);
  }
}
