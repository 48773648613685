/** @define Input; use strict */

.Input {
  width: 100%;
  display: block;
  height: var(--input-height);
  padding: calc(var(--spacing-unit) / 2);
  border: var(--input-border);
  background: white;
  border-radius: 0.1875rem;

  /* STATES */

  &.is-invalid {
    border-color: var(--c-status-bad);
  }
}
