/* Basscss Padding */

@media (--media-480) {

  .p-480-0  { padding: 0 }
  .p-480-t0 { padding-top: 0 }
  .p-480-r0 { padding-right: 0 }
  .p-480-b0 { padding-bottom: 0 }
  .p-480-l0 { padding-left: 0 }
  .p-480-x0 { padding-left: 0; padding-right:  0 }
  .p-480-y0 { padding-top: 0;  padding-bottom: 0 }

  .p-480-05  { padding:        var(--space-05) }
  .p-480-t05 { padding-top:    var(--space-05) }
  .p-480-r05 { padding-right:  var(--space-05) }
  .p-480-b05 { padding-bottom: var(--space-05) }
  .p-480-l05 { padding-left:   var(--space-05) }
  .p-480-y05 { padding-top:    var(--space-05); padding-bottom: var(--space-05) }
  .p-480-x05 { padding-left:   var(--space-05); padding-right:  var(--space-05) }

  .p-480-1  { padding:        var(--space-1) }
  .p-480-t1 { padding-top:    var(--space-1) }
  .p-480-r1 { padding-right:  var(--space-1) }
  .p-480-b1 { padding-bottom: var(--space-1) }
  .p-480-l1 { padding-left:   var(--space-1) }
  .p-480-y1 { padding-top:    var(--space-1); padding-bottom: var(--space-1) }
  .p-480-x1 { padding-left:   var(--space-1); padding-right:  var(--space-1) }

  .p-480-2  { padding:        var(--space-2) }
  .p-480-t2 { padding-top:    var(--space-2) }
  .p-480-r2 { padding-right:  var(--space-2) }
  .p-480-b2 { padding-bottom: var(--space-2) }
  .p-480-l2 { padding-left:   var(--space-2) }
  .p-480-y2 { padding-top:    var(--space-2); padding-bottom: var(--space-2) }
  .p-480-x2 { padding-left:   var(--space-2); padding-right:  var(--space-2) }

  .p-480-3  { padding:        var(--space-3) }
  .p-480-t3 { padding-top:    var(--space-3) }
  .p-480-r3 { padding-right:  var(--space-3) }
  .p-480-b3 { padding-bottom: var(--space-3) }
  .p-480-l3 { padding-left:   var(--space-3) }
  .p-480-y3 { padding-top:    var(--space-3); padding-bottom: var(--space-3) }
  .p-480-x3 { padding-left:   var(--space-3); padding-right:  var(--space-3) }

  .p-480-4  { padding:        var(--space-4) }
  .p-480-t4 { padding-top:    var(--space-4) }
  .p-480-r4 { padding-right:  var(--space-4) }
  .p-480-b4 { padding-bottom: var(--space-4) }
  .p-480-l4 { padding-left:   var(--space-4) }
  .p-480-y4 { padding-top:    var(--space-4); padding-bottom: var(--space-4) }
  .p-480-x4 { padding-left:   var(--space-4); padding-right:  var(--space-4) }

}
