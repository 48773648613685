/**
 * Word breaking
 *
 * Break strings when their length exceeds the width of their container.
 */

@media (--media-1280) {

  .u-1280-textBreak {
    word-wrap: break-word !important;
  }

  /**
   * Horizontal text alignment
   */

  .u-1280-textAlignCenter {
    text-align: center !important;
  }

  .u-1280-textAlignLeft {
    text-align: left !important;
  }

  .u-1280-textAlignRight {
    text-align: right !important;
  }

  /**
   * Inherit the ancestor's text color.
   */

  .u-1280-textColorInherit {
    color: inherit !important;
  }

  /**
   * Enables font kerning in all browsers.
   * http://blog.typekit.com/2014/02/05/kerning-on-the-web/
   *
   * 1. Chrome (not Windows), Firefox, IE 10+
   * 2. Safari 7 and future browsers
   * 3. Chrome (not Windows), Firefox, Safari 6+, iOS, Android
   */

  .u-1280-textKern {
    font-feature-settings: "kern" 1; /* 1 */
    font-kerning: normal; /* 2 */
    text-rendering: optimizeLegibility; /* 3 */
  }

  /**
   * Prevent whitespace wrapping
   */

  .u-1280-textWhiteSpaceNoWrap {
    white-space: nowrap !important;
  }

  /**
   * Text truncation
   *
   * Prevent text from wrapping onto multiple lines, and truncate with an
   * ellipsis.
   *
   * 1. Ensure that the node has a maximum width after which truncation can
   *    occur.
   * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
   *    nodes.
   */

  .u-1280-textTruncate {
    max-width: 100%; /* 1 */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important; /* 2 */
  }

  .u-1280-textSizeXXS {
    font-size: var(--text-size-xxs);
  }

  .u-1280-textSizeXS {
    font-size: var(--text-size-xs);
  }

  .u-1280-textSizeS {
    font-size: var(--text-size-s);
  }

  .u-1280-textSizeM {
    font-size: var(--text-size-m);
  }

  .u-1280-textSizeL {
    font-size: var(--text-size-l);
  }

  .u-1280-textSizeXL {
    font-size: var(--text-size-xl);
  }

  .u-1280-textSizeXXL {
    font-size: var(--text-size-xxl);
  }

  .u-1280-textSizeXXXL {
    font-size: var(--text-size-xxxl);
  }

  .u-1280-textSizeXXXXL {
    font-size: var(--text-size-xxxxl);
  }
}
