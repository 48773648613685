:root {
  --border-radius: 0.25rem;
  --border-radius-default: 0.25rem;
  --border-radius-medium: 0.25rem;
  --border-radius-large: 0.5rem;
  --button-height: 2.25rem;
  --button-height-short: 1.75rem;
  --button-height-large: 4rem;
  --button-square-large: 4rem;
  --circle-with-initials-max-diameter: 4rem;
  --Grid-gutter-size: 20px;
  --Grid-gutter-size-small: 10px;
  --Grid-gutter-size-large: 40px;
  --Grid-gutter-size-extra-large: 80px;
  --header-height: 4.0625rem;
  --icon-size-extra-small: 0.75rem;
  --icon-size-small: 1rem;
  --icon-size-medium: 1.25rem;
  --icon-size-large: 1.5rem;
  --icon-size-extra-large: 1.75rem;
  --icon-size-humongous: 2rem;
  --icon-size-gigantic: 2.25rem;
  --input-border: 1px solid var(--c-grey-light);
  --input-border-highlight: 1px solid var(--c-primary);
  --input-height: 2.25rem;
  --input-height-small: 1.5625;
  --line-graph-height: 6.5rem;
  --logo-width: 5.5rem;
  --max-width: 64rem;
  --max-width-column: 40rem;
  --modal-image-area-max-width: 64rem;
  --modal-image-area-max-height: 64rem;
  --one-rem: 16px;
  --row-height: 2.75rem;
  --section-title-height: 5.25rem;
  --spacing-unit: 1rem;
  --stats-bar-height: 5.125rem;
  --subheader-height: 3.875rem;
  --subheader-large-height: 10rem;
  --text-letter-spacing-m: 0.078125em;
  --text-letter-spacing-l: 0.09375em;
  --text-letter-spacing-xl: 0.125em;
  --text-line-height-xs: 0.875;
  --text-line-height-s: 1.25;
  --text-line-height-m: 1.6;
  --text-line-height-l: 1.75;
  --text-line-height-xl: 2;
  --text-line-height-xxl: 2.5;
  --text-line-height-xxxl: 3.25;
  --text-size-xxs: 0.6875rem;
  --text-size-xs: 0.75rem;
  --text-size-s: 0.875rem;
  --text-size-m: 1rem;
  --text-size-l: 1.25rem;
  --text-size-xl: 1.5rem;
  --text-size-xxl: 2rem;
  --text-size-xxxl: 3rem;
  --text-size-xxxxl: 4rem;
  --text-size-xxxxxl: 4.5rem;
  --text-weight-light: 300;
  --text-weight-regular: 400;
  --text-weight-semi-bold: 600;
  --thumbnail-max-height: 11.25rem;
  --border-thin: 0.125rem;
  --border-hairline: 0.0625rem;
  --border-medium: 0.1875rem;
  --border-thick: 0.25rem;
  --border-thick-x2: 0.5rem;
  --border-thick-x3: 1rem;
  --space-05: 0.25rem;
  --space-1: 0.5rem;
  --space-2: 1rem;
  --space-3: 2rem;
  --space-4: 4rem;

  --utils-css-text-size-xxs: 0.6875rem;
  --utils-css-text-size-xs: 0.75rem;
  --utils-css-text-size-s: 0.875rem;
  --utils-css-text-size-m: 1rem;
  --utils-css-text-size-l: 1.25rem;
  --utils-css-text-size-xl: 1.5rem;
  --utils-css-text-size-xxl: 2rem;
  --utils-css-text-size-xxxl: 3rem;
  --utils-css-text-size-xxxxl: 4rem;
  --utils-css-text-size-xxxxxl: 4.5rem;
  --utils-css-text-letter-spacing-m: 0.078125em;
  --utils-css-text-letter-spacing-l: 0.09375em;
  --utils-css-text-letter-spacing-xl: 0.125em;
  --utils-css-text-line-height-xs: 0.875;
  --utils-css-text-line-height-s: 1.25;
  --utils-css-text-line-height-m: 1.6;
  --utils-css-text-line-height-l: 1.75;
  --utils-css-text-line-height-xl: 2;
  --utils-css-text-line-height-xxl: 2.5;
  --utils-css-text-line-height-xxxl: 3.25;
  --utils-css-c-primary: #00bbe3;
  --utils-css-c-grey-lightest: #f7f7f7;
  --utils-css-c-grey-lighter: #eee;
  --utils-css-c-grey-lighterish: #ededed;
  --utils-css-c-grey-light: #ccc;
  --utils-css-c-grey: #999;
  --utils-css-c-grey-dark: #616668;
  --utils-css-c-grey-darkest: #333;
  --utils-css-c-status-success: #43ac6a;
  --utils-css-c-status-warning: #f6ab16;
  --utils-css-c-status-error: #e65c73;
  --utils-css-text-weight-light: 300;
  --utils-css-text-weight-regular: 400;
  --utils-css-text-weight-semi-bold: 600;
  --utils-css-border-hairline: 0.0625rem;
  --utils-css-border-medium: 0.1875rem;
  --utils-css-border-thick: 0.25rem;
  --utils-css-border-thick-x2: 0.5rem;
  --utils-css-border-thick-x3: 1rem;
  --utils-css-border-radius-small: 0.25rem;
  --utils-css-border-radius-medium: 0.25rem;
  --utils-css-border-radius-large: 0.5rem;
  --utils-css-max-width-default: 64rem;
  --utils-css-icon-size-extra-small: 0.75rem;
  --utils-css-icon-size-small: 1rem;
  --utils-css-icon-size-medium: 1.25rem;
  --utils-css-icon-size-large: 1.5rem;
  --utils-css-icon-size-extra-large: 1.75rem;
  --utils-css-icon-size-humongous: 2rem;
  --utils-css-icon-size-gigantic: 2.25rem;
}

/* Custom media queries */

@custom-media --handheld (max-width: 30em);
@custom-media --tablet (max-width: 64em);
@custom-media --desktop (min-width: 64.000001em);
@custom-media --media-480 (min-width: 30em);
@custom-media --media-768 (min-width: 48em);
@custom-media --media-1024 (min-width: 64em);
@custom-media --media-1280 (min-width: 80em);
@custom-media --media-portrait (orientation: portrait);
@custom-media --media-landscape (orientation: landscape);

/*
 * TODO These should be revisited later when this can be done correctly.
 * See https://github.com/PrecisionNutrition/coaching-assistant/pull/43
 */

@custom-media --sm-viewport (min-width: 320px) and (max-width: 640px);
@custom-media --md-viewport (min-width: 640px) and (max-width: 960px);
@custom-media --lg-viewport (min-width: 960px);
