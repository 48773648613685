/* Basscss Padding */

@media (--media-1280) {

  .p-1280-0  { padding: 0 }
  .p-1280-t0 { padding-top: 0 }
  .p-1280-r0 { padding-right: 0 }
  .p-1280-b0 { padding-bottom: 0 }
  .p-1280-l0 { padding-left: 0 }
  .p-1280-x0 { padding-left: 0; padding-right:  0 }
  .p-1280-y0 { padding-top: 0;  padding-bottom: 0 }

  .p-1280-05  { padding:        var(--space-05) }
  .p-1280-t05 { padding-top:    var(--space-05) }
  .p-1280-r05 { padding-right:  var(--space-05) }
  .p-1280-b05 { padding-bottom: var(--space-05) }
  .p-1280-l05 { padding-left:   var(--space-05) }
  .p-1280-y05 { padding-top:    var(--space-05); padding-bottom: var(--space-05) }
  .p-1280-x05 { padding-left:   var(--space-05); padding-right:  var(--space-05) }

  .p-1280-1  { padding:        var(--space-1) }
  .p-1280-t1 { padding-top:    var(--space-1) }
  .p-1280-r1 { padding-right:  var(--space-1) }
  .p-1280-b1 { padding-bottom: var(--space-1) }
  .p-1280-l1 { padding-left:   var(--space-1) }
  .p-1280-y1 { padding-top:    var(--space-1); padding-bottom: var(--space-1) }
  .p-1280-x1 { padding-left:   var(--space-1); padding-right:  var(--space-1) }

  .p-1280-2  { padding:        var(--space-2) }
  .p-1280-t2 { padding-top:    var(--space-2) }
  .p-1280-r2 { padding-right:  var(--space-2) }
  .p-1280-b2 { padding-bottom: var(--space-2) }
  .p-1280-l2 { padding-left:   var(--space-2) }
  .p-1280-y2 { padding-top:    var(--space-2); padding-bottom: var(--space-2) }
  .p-1280-x2 { padding-left:   var(--space-2); padding-right:  var(--space-2) }

  .p-1280-3  { padding:        var(--space-3) }
  .p-1280-t3 { padding-top:    var(--space-3) }
  .p-1280-r3 { padding-right:  var(--space-3) }
  .p-1280-b3 { padding-bottom: var(--space-3) }
  .p-1280-l3 { padding-left:   var(--space-3) }
  .p-1280-y3 { padding-top:    var(--space-3); padding-bottom: var(--space-3) }
  .p-1280-x3 { padding-left:   var(--space-3); padding-right:  var(--space-3) }

  .p-1280-4  { padding:        var(--space-4) }
  .p-1280-t4 { padding-top:    var(--space-4) }
  .p-1280-r4 { padding-right:  var(--space-4) }
  .p-1280-b4 { padding-bottom: var(--space-4) }
  .p-1280-l4 { padding-left:   var(--space-4) }
  .p-1280-y4 { padding-top:    var(--space-4); padding-bottom: var(--space-4) }
  .p-1280-x4 { padding-left:   var(--space-4); padding-right:  var(--space-4) }

}
