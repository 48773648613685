/**
 * @define Button
 * use strict
 */

.Button {
  cursor: pointer;
  padding: 0.5rem 1rem;
  display: inline-block;
  border-radius: var(--border-radius);

  /* Modifiers */

  &--primary {
    background: var(--c-primary);
    color: white;

    &:hover {
      background: var(--c-interactive);
      color: white;
    }
  }

  &--white {
    background: white;
    border: 0.0625rem solid var(--c-primary);
    color: var(--c-primary);

    &:hover {
      border-color: var(--c-interactive);
      color: var(--c-interactive);
    }
  }

  &--nav {
    font-size: var(--text-size-s);
    cursor: pointer;

    &:hover {
      color: var(--c-primary);
    }
  }

  &--sidebar {
    display: block;
    width: 100%;
    font-size: var(--text-size-xl);
    text-align: left;
    border-bottom: 0.0625rem solid var(--c-grey-light);
  }

  &--carouselLeft,
  &--carouselRight {
    position: absolute;
    padding: 0.5rem;
    top: calc(50% - 2rem);
    z-index: var(--z-carousel-buttons);
  }

  &--carouselLeft {
    left: -1.75rem;

    @media (--media-480) {
      left: -2.25rem;
    }

    @media (--media-768) {
      left: -2.5rem;
    }
  }

  &--carouselRight {
    right: -1.75rem;

    @media (--media-480) {
      right: -2.25rem;
    }

    @media (--media-768) {
      right: -2.5rem;
    }
  }

  /* STATES */

  &--nav.is-active {
    color: var(--c-primary) !important;
  }
}
