/**
  @define Rule;
  use strict
  */

.Rule {
  width: 100%;
  height: 0;
  background: none;
  border-bottom: 1px solid var(--c-grey-lighter);

  /* Modifiers */

  &--gap {
    border: none;
  }

  &--dotted {
    border: none;
    border-bottom: 1px dotted var(--c-grey-lighter);
  }
}
