/* Basscss Padding */

@media (--media-1024) {

  .p-1024-0  { padding: 0 }
  .p-1024-t0 { padding-top: 0 }
  .p-1024-r0 { padding-right: 0 }
  .p-1024-b0 { padding-bottom: 0 }
  .p-1024-l0 { padding-left: 0 }
  .p-1024-x0 { padding-left: 0; padding-right:  0 }
  .p-1024-y0 { padding-top: 0;  padding-bottom: 0 }

  .p-1024-05  { padding:        var(--space-05) }
  .p-1024-t05 { padding-top:    var(--space-05) }
  .p-1024-r05 { padding-right:  var(--space-05) }
  .p-1024-b05 { padding-bottom: var(--space-05) }
  .p-1024-l05 { padding-left:   var(--space-05) }
  .p-1024-y05 { padding-top:    var(--space-05); padding-bottom: var(--space-05) }
  .p-1024-x05 { padding-left:   var(--space-05); padding-right:  var(--space-05) }

  .p-1024-1  { padding:        var(--space-1) }
  .p-1024-t1 { padding-top:    var(--space-1) }
  .p-1024-r1 { padding-right:  var(--space-1) }
  .p-1024-b1 { padding-bottom: var(--space-1) }
  .p-1024-l1 { padding-left:   var(--space-1) }
  .p-1024-y1 { padding-top:    var(--space-1); padding-bottom: var(--space-1) }
  .p-1024-x1 { padding-left:   var(--space-1); padding-right:  var(--space-1) }

  .p-1024-2  { padding:        var(--space-2) }
  .p-1024-t2 { padding-top:    var(--space-2) }
  .p-1024-r2 { padding-right:  var(--space-2) }
  .p-1024-b2 { padding-bottom: var(--space-2) }
  .p-1024-l2 { padding-left:   var(--space-2) }
  .p-1024-y2 { padding-top:    var(--space-2); padding-bottom: var(--space-2) }
  .p-1024-x2 { padding-left:   var(--space-2); padding-right:  var(--space-2) }

  .p-1024-3  { padding:        var(--space-3) }
  .p-1024-t3 { padding-top:    var(--space-3) }
  .p-1024-r3 { padding-right:  var(--space-3) }
  .p-1024-b3 { padding-bottom: var(--space-3) }
  .p-1024-l3 { padding-left:   var(--space-3) }
  .p-1024-y3 { padding-top:    var(--space-3); padding-bottom: var(--space-3) }
  .p-1024-x3 { padding-left:   var(--space-3); padding-right:  var(--space-3) }

  .p-1024-4  { padding:        var(--space-4) }
  .p-1024-t4 { padding-top:    var(--space-4) }
  .p-1024-r4 { padding-right:  var(--space-4) }
  .p-1024-b4 { padding-bottom: var(--space-4) }
  .p-1024-l4 { padding-left:   var(--space-4) }
  .p-1024-y4 { padding-top:    var(--space-4); padding-bottom: var(--space-4) }
  .p-1024-x4 { padding-left:   var(--space-4); padding-right:  var(--space-4) }

}
