/**
 * @define Sidebar
 * use strict
 */

.Sidebar {
  width: 100%;
  height: 100vh;
  top: -100vh;
  transition: top 0.25s ease-out, opacity 0.25s;
  z-index: var(--z-sidebar);

  @media (--media-768) {
    top: -100vh !important;
  }

  /* STATES */

  &.is-open {
    top: var(--header-height) !important;
  }
}
