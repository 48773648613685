/**
 * Display-type utilities
 */

@media (--media-1024) {

  .u-1024-displayBlock {
    display: block !important;
  }

  .u-1024-displayNone {
    display: none !important;
  }

  .u-1024-displayHidden {
    visibility: hidden !important;
  }

  /**
   * Completely remove from the flow but leave available to screen readers.
   */

  .u-1024-displayHiddenVisually {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }

  .u-1024-displayInline {
    display: inline !important;
  }

  /**
   * 1. Fix for Firefox bug: an image styled `max-width:100%` within an
   * inline-block will display at its default size, and not limit its width to
   * 100% of an ancestral container.
   */

  .u-1024-displayInlineBlock {
    display: inline-block !important;
    max-width: 100%; /* 1 */
  }

  .u-1024-displayTable {
    display: table !important;
  }

  .u-1024-displayTableCell {
    display: table-cell !important;
  }

  .u-1024-displayTableRow {
    display: table-row !important;
  }
}
