/* blame.css */

.Grid--withLargeGutter {
  margin-left: calc(-0.5 * var(--Grid-gutter-size-extra-large));
  width: calc(100% + var(--Grid-gutter-size-large));
}

.Grid--withLargeGutter > * {
  padding-left: calc(0.5 * var(--Grid-gutter-size-extra-large));
  padding-right: 0;
}

.Grid--withSmallGutter {
  margin-left: calc(-0.5 * var(--Grid-gutter-size-small));
  width: calc(100% + var(--Grid-gutter-size));
}

.Grid--withSmallGutter > * {
  padding-left: calc(0.5 * var(--Grid-gutter-size-small));
  padding-right: 0;
}

.Grid--withGutter.u-flexCol {
  margin: calc(-0.5 * var(--Grid-gutter-size)) 0;
}

.Grid--withGutter.u-flexCol > * {
  padding: calc(0.5 * var(--Grid-gutter-size)) 0;
}

.Section.Grid {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.u-floatLeft {
  float: left;
}

.u-fontFamilyRockSalt {
  font-family: 'Rock Salt', cursive;
}

.u-listStyleNone {
  list-style: none;
}

.u-positionInherit {
  position: inherit;
}

.u-shadowLarge {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.4);
}

.u-textLineHeightCustom {
  line-height: 1.125;
}

.u-textLineHeightCustom2 {
  line-height: 1.4;
}

.u-textSizeXXXXXL {
  font-size: var(--text-size-xxxxxl);
}

@media (--media-480) {
  .u-480-maxWidth20 {
    max-width: 20rem;
  }

  .Section.Grid.p-480-x3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (--media-768) {
  .Section--meetCoach {
    padding-left: 0;
    padding-right: 2rem;
  }

  .u-768-flexNoWrap {
    flex-wrap: nowrap !important;
  }

  .u-768-maxWidth28 {
    max-width: 28rem !important;
  }

  .u-768-textAlignLeft {
    text-align: left !important;
  }

  .u-768-textLineHeightM {
    line-height: var(--text-line-height-m) !important;
  }

  .u-768-displayFlex {
    display: flex !important;
  }

  .u-768-maxWidth20 {
    max-width: 20rem;
  }
}

@media (--media-1024) {
  .Grid-1024-alignLeft {
    justify-content: flex-start;
  }
}
