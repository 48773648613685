/**
 * Word breaking
 *
 * Break strings when their length exceeds the width of their container.
 */

@media (--media-768) {

  .u-768-textBreak {
    word-wrap: break-word !important;
  }

  /**
   * Horizontal text alignment
   */

  .u-768-textAlignCenter {
    text-align: center !important;
  }

  .u-768-textAlignLeft {
    text-align: left !important;
  }

  .u-768-textAlignRight {
    text-align: right !important;
  }

  /**
   * Inherit the ancestor's text color.
   */

  .u-768-textColorInherit {
    color: inherit !important;
  }

  /**
   * Enables font kerning in all browsers.
   * http://blog.typekit.com/2014/02/05/kerning-on-the-web/
   *
   * 1. Chrome (not Windows), Firefox, IE 10+
   * 2. Safari 7 and future browsers
   * 3. Chrome (not Windows), Firefox, Safari 6+, iOS, Android
   */

  .u-768-textKern {
    font-feature-settings: "kern" 1; /* 1 */
    font-kerning: normal; /* 2 */
    text-rendering: optimizeLegibility; /* 3 */
  }

  /**
   * Prevent whitespace wrapping
   */

  .u-768-textWhiteSpaceNoWrap {
    white-space: nowrap !important;
  }

  /**
   * Text truncation
   *
   * Prevent text from wrapping onto multiple lines, and truncate with an
   * ellipsis.
   *
   * 1. Ensure that the node has a maximum width after which truncation can
   *    occur.
   * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
   *    nodes.
   */

  .u-768-textTruncate {
    max-width: 100%; /* 1 */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important; /* 2 */
  }

  .u-768-textSizeXXS {
    font-size: var(--text-size-xxs) !important;
  }

  .u-768-textSizeXS {
    font-size: var(--text-size-xs) !important;
  }

  .u-768-textSizeS {
    font-size: var(--text-size-s) !important;
  }

  .u-768-textSizeM {
    font-size: var(--text-size-m) !important;
  }

  .u-768-textSizeL {
    font-size: var(--text-size-l) !important;
  }

  .u-768-textSizeXL {
    font-size: var(--text-size-xl) !important;
  }

  .u-768-textSizeXXL {
    font-size: var(--text-size-xxl) !important;
  }

  .u-768-textSizeXXXL {
    font-size: var(--text-size-xxxl) !important;
  }

  .u-768-textSizeXXXXL {
    font-size: var(--text-size-xxxxl) !important;
  }
}
