/* Basscss Margin */

@media (--media-1280) {

  .m-1280-0  { margin:        0 }
  .m-1280-t0 { margin-top:    0 }
  .m-1280-r0 { margin-right:  0 }
  .m-1280-b0 { margin-bottom: 0 }
  .m-1280-l0 { margin-left:   0 }
  .m-1280-x0 { margin-left:   0; margin-right:  0 }
  .m-1280-y0 { margin-top:    0; margin-bottom: 0 }

  .m-1280-05  { margin:        var(--space-05) }
  .m-1280-t05 { margin-top:    var(--space-05) }
  .m-1280-r05 { margin-right:  var(--space-05) }
  .m-1280-b05 { margin-bottom: var(--space-05) }
  .m-1280-l05 { margin-left:   var(--space-05) }
  .m-1280-x05 { margin-left:   var(--space-05); margin-right:  var(--space-05) }
  .m-1280-y05 { margin-top:    var(--space-05); margin-bottom: var(--space-05) }

  .m-1280-1  { margin:        var(--space-1) }
  .m-1280-t1 { margin-top:    var(--space-1) }
  .m-1280-r1 { margin-right:  var(--space-1) }
  .m-1280-b1 { margin-bottom: var(--space-1) }
  .m-1280-l1 { margin-left:   var(--space-1) }
  .m-1280-x1 { margin-left:   var(--space-1); margin-right:  var(--space-1) }
  .m-1280-y1 { margin-top:    var(--space-1); margin-bottom: var(--space-1) }

  .m-1280-2  { margin:        var(--space-2) }
  .m-1280-t2 { margin-top:    var(--space-2) }
  .m-1280-r2 { margin-right:  var(--space-2) }
  .m-1280-b2 { margin-bottom: var(--space-2) }
  .m-1280-l2 { margin-left:   var(--space-2) }
  .m-1280-x2 { margin-left:   var(--space-2); margin-right:  var(--space-2) }
  .m-1280-y2 { margin-top:    var(--space-2); margin-bottom: var(--space-2) }

  .m-1280-3  { margin:        var(--space-3) }
  .m-1280-t3 { margin-top:    var(--space-3) }
  .m-1280-r3 { margin-right:  var(--space-3) }
  .m-1280-b3 { margin-bottom: var(--space-3) }
  .m-1280-l3 { margin-left:   var(--space-3) }
  .m-1280-x3 { margin-left:   var(--space-3); margin-right:  var(--space-3) }
  .m-1280-y3 { margin-top:    var(--space-3); margin-bottom: var(--space-3) }

  .m-1280-4  { margin:        var(--space-4) }
  .m-1280-t4 { margin-top:    var(--space-4) }
  .m-1280-r4 { margin-right:  var(--space-4) }
  .m-1280-b4 { margin-bottom: var(--space-4) }
  .m-1280-l4 { margin-left:   var(--space-4) }
  .m-1280-x4 { margin-left:   var(--space-4); margin-right:  var(--space-4) }
  .m-1280-y4 { margin-top:    var(--space-4); margin-bottom: var(--space-4) }

  .m-1280-xn1 { margin-left: calc(-1 * var(--space-1)); margin-right: calc(-1 * var(--space-1)); }
  .m-1280-xn2 { margin-left: calc(-1 * var(--space-2)); margin-right: calc(-1 * var(--space-2)); }
  .m-1280-xn3 { margin-left: calc(-1 * var(--space-3)); margin-right: calc(-1 * var(--space-3)); }
  .m-1280-xn4 { margin-left: calc(-1 * var(--space-4)); margin-right: calc(-1 * var(--space-4)); }

  .m-1280-tn05 { margin-top: calc(-1 * var(--space-05)); }
  .m-1280-tn1  { margin-top: calc(-1 * var(--space-1));  }
  .m-1280-tn2  { margin-top: calc(-1 * var(--space-2));  }
  .m-1280-tn3  { margin-top: calc(-1 * var(--space-3));  }
  .m-1280-tn4  { margin-top: calc(-1 * var(--space-4));  }

  .m-1280-l-auto { margin-left: auto }
  .m-1280-r-auto { margin-right: auto }
  .m-1280-x-auto { margin-left: auto; margin-right: auto; }
  .m-1280-y-auto { margin-top: auto; margin-bottom: auto; }

}
