/**
 * @define SuperModal
 * use strict
 */

.SuperModal {
  display: none;

  /* Elements */

  &-outer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 1rem !important;
  }

  &-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 15, 27, 0.9);
    z-index: 110;
  }

  &-inner {
    margin: auto;
    flex-basis: auto !important;
    overflow-y: auto;
    max-height: 100%;
    position: relative;
    z-index: 120;
    background-color: white;
    border-radius: 0.25rem;
    padding: 2rem 1rem;
    width: 100%;
    max-width: 32rem;

    @media (--media-480) {
      padding: 4rem 2rem;
    }
  }

  &-closeButton {
    @apply text-2xl;
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 3rem;
    z-index: 130;
    font-weight: bold;
  }

  /* States */

  &.is-open {
    display: block;
  }

  /* Remove when fixed by Apple in WebKit.
     Ref: https://bugs.webkit.org/show_bug.cgi?id=176896 */
  &.is-ios11 {
    .SuperModal-outer {
      position: absolute;
    }
  }
}
