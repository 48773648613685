/* This is a customized version of suitcss-utils-size
 *
 * The goal is to move this into it's own npm module as
 * a fork of the above mentioned module, but with more than
 * the default three sizes, to better work with our breakpoints.
 *
*/

@import "./lib/size.css";
@import "./lib/size-480.css";
@import "./lib/size-768.css";
@import "./lib/size-1024.css";
@import "./lib/size-1280.css";
