/**
 * @define Image
 * use strict
 */

.Image {
  width: 100%;
  max-width: 100%;

  &--hero {
    background-image: url(../../../../images/salesprofile-headline-image0.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: var(--c-hero-background);
  }

  &--option0 {
    background-image: url(../../../../images/salesprofile-headline-image0.jpg);
  }

  &--option1 {
    background-image: url(../../../../images/salesprofile-headline-image1.jpg);
  }

  &--option2 {
    background-image: url(../../../../images/salesprofile-headline-image2.jpg);
  }

  &--option3 {
    background-image: url(../../../../images/salesprofile-headline-image3.jpg);
  }

  &--option4 {
    background-image: url(../../../../images/salesprofile-headline-image4.jpg);
  }

  &--hexagon,
  &--hexagonButton {
    background-image: url(../../../../images/pn-hexagon-blank.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-width: 2.3rem !important;
    min-height: 2rem;
  }
}
