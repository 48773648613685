:root {
  --c-primary: #00bbe3;
  --c-blue-dark: #003a63;
  --c-blue-dark-navy-90: rgba(0, 15, 27, 0.9);
  --c-grey-lightest: #f7f7f7;
  --c-grey-lighter: #eee;
  --c-grey-lighterish: #ededed;
  --c-grey-light: #ccc;
  --c-grey: #999;
  --c-grey-dark: #616668;
  --c-grey-darkest: #333;
  --c-interactive: #22829f;
  --c-status-good: #43ac6a;
  --c-status-warning: #f6ab16;
  --c-status-bad: #e65c73;
}
