/* Basscss Margin */

@media (--media-768) {

  .m-768-0  { margin:        0 }
  .m-768-t0 { margin-top:    0 }
  .m-768-r0 { margin-right:  0 }
  .m-768-b0 { margin-bottom: 0 }
  .m-768-l0 { margin-left:   0 }
  .m-768-x0 { margin-left:   0; margin-right:  0 }
  .m-768-y0 { margin-top:    0; margin-bottom: 0 }

  .m-768-05  { margin:        var(--space-05) }
  .m-768-t05 { margin-top:    var(--space-05) }
  .m-768-r05 { margin-right:  var(--space-05) }
  .m-768-b05 { margin-bottom: var(--space-05) }
  .m-768-l05 { margin-left:   var(--space-05) }
  .m-768-x05 { margin-left:   var(--space-05); margin-right:  var(--space-05) }
  .m-768-y05 { margin-top:    var(--space-05); margin-bottom: var(--space-05) }

  .m-768-1  { margin:        var(--space-1) }
  .m-768-t1 { margin-top:    var(--space-1) }
  .m-768-r1 { margin-right:  var(--space-1) }
  .m-768-b1 { margin-bottom: var(--space-1) }
  .m-768-l1 { margin-left:   var(--space-1) }
  .m-768-x1 { margin-left:   var(--space-1); margin-right:  var(--space-1) }
  .m-768-y1 { margin-top:    var(--space-1); margin-bottom: var(--space-1) }

  .m-768-2  { margin:        var(--space-2) }
  .m-768-t2 { margin-top:    var(--space-2) }
  .m-768-r2 { margin-right:  var(--space-2) }
  .m-768-b2 { margin-bottom: var(--space-2) }
  .m-768-l2 { margin-left:   var(--space-2) }
  .m-768-x2 { margin-left:   var(--space-2); margin-right:  var(--space-2) }
  .m-768-y2 { margin-top:    var(--space-2); margin-bottom: var(--space-2) }

  .m-768-3  { margin:        var(--space-3) }
  .m-768-t3 { margin-top:    var(--space-3) }
  .m-768-r3 { margin-right:  var(--space-3) }
  .m-768-b3 { margin-bottom: var(--space-3) }
  .m-768-l3 { margin-left:   var(--space-3) }
  .m-768-x3 { margin-left:   var(--space-3); margin-right:  var(--space-3) }
  .m-768-y3 { margin-top:    var(--space-3); margin-bottom: var(--space-3) }

  .m-768-4  { margin:        var(--space-4) }
  .m-768-t4 { margin-top:    var(--space-4) }
  .m-768-r4 { margin-right:  var(--space-4) }
  .m-768-b4 { margin-bottom: var(--space-4) }
  .m-768-l4 { margin-left:   var(--space-4) }
  .m-768-x4 { margin-left:   var(--space-4); margin-right:  var(--space-4) }
  .m-768-y4 { margin-top:    var(--space-4); margin-bottom: var(--space-4) }

  .m-768-xn1 { margin-left: calc(-1 * var(--space-1)); margin-right: calc(-1 * var(--space-1)); }
  .m-768-xn2 { margin-left: calc(-1 * var(--space-2)); margin-right: calc(-1 * var(--space-2)); }
  .m-768-xn3 { margin-left: calc(-1 * var(--space-3)); margin-right: calc(-1 * var(--space-3)); }
  .m-768-xn4 { margin-left: calc(-1 * var(--space-4)); margin-right: calc(-1 * var(--space-4)); }

  .m-768-tn05 { margin-top: calc(-1 * var(--space-05)); }
  .m-768-tn1  { margin-top: calc(-1 * var(--space-1));  }
  .m-768-tn2  { margin-top: calc(-1 * var(--space-2));  }
  .m-768-tn3  { margin-top: calc(-1 * var(--space-3));  }
  .m-768-tn4  { margin-top: calc(-1 * var(--space-4));  }

  .m-768-l-auto { margin-left: auto }
  .m-768-r-auto { margin-right: auto }
  .m-768-x-auto { margin-left: auto; margin-right: auto; }
  .m-768-y-auto { margin-top: auto; margin-bottom: auto; }

}
