* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
  color: var(--c-text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
  line-height: 1.25;
}

h1 {
  font-size: var(--text-size-xxl);
}

h2 {
  font-size: var(--text-size-xl);
}

h3 {
  font-size: var(--text-size-l);
}

h4 {
  font-size: var(--text-size-m);
}

h5 {
  font-size: var(--text-size-s);
}

h6 {
  font-size: var(--text-size-xs);
}

hr {
  border: 0;
  height: 0.0625rem;
  border-top: 0.0625rem solid var(--c-grey-light);
}

button {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background: transparent;
}

dl,
ul {
  margin: 0;
}

ol,
ul {
  margin-bottom: 1rem;
}

q {
  quotes: '“' '”' '‘' '’';
}

p {
  margin-top: 0;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

a {
  color: var(--c-primary);
  text-decoration: none;

  &:hover {
    color: var(--c-blue-dark);
  }
}
