/** @define utilities */

.u-backgroundBlack {
  background: black;
}

.u-backgroundBlueDark {
  background: var(--c-blue-dark);
}

.u-backgroundBlueDarkNavy90P {
  background: var(--c-blue-dark-navy-90);
}

.u-backgroundWarning20P {
  background-color: rgba(246, 171, 22, 0.2) !important;
}
