/** @define utilities */

.u-colourDanger {
  color: var(--c-status-bad);
}

.u-colourBlueDark {
  color: var(--c-blue-dark);
}

.u-colourPrimarySoft {
  color: var(--c-primary);
}
