/**
 * @define Slide
 * use strict
 */

.Slide {
  outline: 0;
  height: 100%;
  min-height: 100%;

  &-inner {
    height: 100%;
    width: 100%;
  }

  &-image {
    width: 100%;
    background-size: cover;
    background-position: top center;
  }

  &-placeholder {
    width: 100%;
    height: 100%;
  }
}
