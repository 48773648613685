/**
 * @define Nav
 * use strict
 */

.Nav {
  width: 100%;
  position: fixed;
  top: calc(-1 * var(--header-height));
  left: 0;
  transition: top 0.25s ease-out;
  z-index: var(--z-nav);

  &-cover {
    width: 100%;
    position: fixed;
    top: -4rem;
    left: 0;
    z-index: var(--z-nav-cover);
    background: white;
    height: 4rem;
  }

  /* STATES */

  &.is-showing {
    top: 0 !important;
  }
}
