/* Basscss Padding */

@media (--media-768) {

  .p-768-0  { padding: 0 }
  .p-768-t0 { padding-top: 0 }
  .p-768-r0 { padding-right: 0 }
  .p-768-b0 { padding-bottom: 0 }
  .p-768-l0 { padding-left: 0 }
  .p-768-x0 { padding-left: 0; padding-right:  0 }
  .p-768-y0 { padding-top: 0;  padding-bottom: 0 }

  .p-768-05  { padding:        var(--space-05) }
  .p-768-t05 { padding-top:    var(--space-05) }
  .p-768-r05 { padding-right:  var(--space-05) }
  .p-768-b05 { padding-bottom: var(--space-05) }
  .p-768-l05 { padding-left:   var(--space-05) }
  .p-768-y05 { padding-top:    var(--space-05); padding-bottom: var(--space-05) }
  .p-768-x05 { padding-left:   var(--space-05); padding-right:  var(--space-05) }

  .p-768-1  { padding:        var(--space-1) }
  .p-768-t1 { padding-top:    var(--space-1) }
  .p-768-r1 { padding-right:  var(--space-1) }
  .p-768-b1 { padding-bottom: var(--space-1) }
  .p-768-l1 { padding-left:   var(--space-1) }
  .p-768-y1 { padding-top:    var(--space-1); padding-bottom: var(--space-1) }
  .p-768-x1 { padding-left:   var(--space-1); padding-right:  var(--space-1) }

  .p-768-2  { padding:        var(--space-2) }
  .p-768-t2 { padding-top:    var(--space-2) }
  .p-768-r2 { padding-right:  var(--space-2) }
  .p-768-b2 { padding-bottom: var(--space-2) }
  .p-768-l2 { padding-left:   var(--space-2) }
  .p-768-y2 { padding-top:    var(--space-2); padding-bottom: var(--space-2) }
  .p-768-x2 { padding-left:   var(--space-2); padding-right:  var(--space-2) }

  .p-768-3  { padding:        var(--space-3) }
  .p-768-t3 { padding-top:    var(--space-3) }
  .p-768-r3 { padding-right:  var(--space-3) }
  .p-768-b3 { padding-bottom: var(--space-3) }
  .p-768-l3 { padding-left:   var(--space-3) }
  .p-768-y3 { padding-top:    var(--space-3); padding-bottom: var(--space-3) }
  .p-768-x3 { padding-left:   var(--space-3); padding-right:  var(--space-3) }

  .p-768-4  { padding:        var(--space-4) }
  .p-768-t4 { padding-top:    var(--space-4) }
  .p-768-r4 { padding-right:  var(--space-4) }
  .p-768-b4 { padding-bottom: var(--space-4) }
  .p-768-l4 { padding-left:   var(--space-4) }
  .p-768-y4 { padding-top:    var(--space-4); padding-bottom: var(--space-4) }
  .p-768-x4 { padding-left:   var(--space-4); padding-right:  var(--space-4) }

}
