/* Basscss Margin */

@media (--media-480) {

  .m-480-0  { margin:        0 }
  .m-480-t0 { margin-top:    0 }
  .m-480-r0 { margin-right:  0 }
  .m-480-b0 { margin-bottom: 0 }
  .m-480-l0 { margin-left:   0 }
  .m-480-x0 { margin-left:   0; margin-right:  0 }
  .m-480-y0 { margin-top:    0; margin-bottom: 0 }

  .m-480-05  { margin:        var(--space-05) }
  .m-480-t05 { margin-top:    var(--space-05) }
  .m-480-r05 { margin-right:  var(--space-05) }
  .m-480-b05 { margin-bottom: var(--space-05) }
  .m-480-l05 { margin-left:   var(--space-05) }
  .m-480-x05 { margin-left:   var(--space-05); margin-right:  var(--space-05) }
  .m-480-y05 { margin-top:    var(--space-05); margin-bottom: var(--space-05) }

  .m-480-1  { margin:        var(--space-1) }
  .m-480-t1 { margin-top:    var(--space-1) }
  .m-480-r1 { margin-right:  var(--space-1) }
  .m-480-b1 { margin-bottom: var(--space-1) }
  .m-480-l1 { margin-left:   var(--space-1) }
  .m-480-x1 { margin-left:   var(--space-1); margin-right:  var(--space-1) }
  .m-480-y1 { margin-top:    var(--space-1); margin-bottom: var(--space-1) }

  .m-480-2  { margin:        var(--space-2) }
  .m-480-t2 { margin-top:    var(--space-2) }
  .m-480-r2 { margin-right:  var(--space-2) }
  .m-480-b2 { margin-bottom: var(--space-2) }
  .m-480-l2 { margin-left:   var(--space-2) }
  .m-480-x2 { margin-left:   var(--space-2); margin-right:  var(--space-2) }
  .m-480-y2 { margin-top:    var(--space-2); margin-bottom: var(--space-2) }

  .m-480-3  { margin:        var(--space-3) }
  .m-480-t3 { margin-top:    var(--space-3) }
  .m-480-r3 { margin-right:  var(--space-3) }
  .m-480-b3 { margin-bottom: var(--space-3) }
  .m-480-l3 { margin-left:   var(--space-3) }
  .m-480-x3 { margin-left:   var(--space-3); margin-right:  var(--space-3) }
  .m-480-y3 { margin-top:    var(--space-3); margin-bottom: var(--space-3) }

  .m-480-4  { margin:        var(--space-4) }
  .m-480-t4 { margin-top:    var(--space-4) }
  .m-480-r4 { margin-right:  var(--space-4) }
  .m-480-b4 { margin-bottom: var(--space-4) }
  .m-480-l4 { margin-left:   var(--space-4) }
  .m-480-x4 { margin-left:   var(--space-4); margin-right:  var(--space-4) }
  .m-480-y4 { margin-top:    var(--space-4); margin-bottom: var(--space-4) }

  .m-480-xn1 { margin-left: calc(-1 * var(--space-1)); margin-right: calc(-1 * var(--space-1)); }
  .m-480-xn2 { margin-left: calc(-1 * var(--space-2)); margin-right: calc(-1 * var(--space-2)); }
  .m-480-xn3 { margin-left: calc(-1 * var(--space-3)); margin-right: calc(-1 * var(--space-3)); }
  .m-480-xn4 { margin-left: calc(-1 * var(--space-4)); margin-right: calc(-1 * var(--space-4)); }

  .m-480-tn05 { margin-top: calc(-1 * var(--space-05)); }
  .m-480-tn1  { margin-top: calc(-1 * var(--space-1));  }
  .m-480-tn2  { margin-top: calc(-1 * var(--space-2));  }
  .m-480-tn3  { margin-top: calc(-1 * var(--space-3));  }
  .m-480-tn4  { margin-top: calc(-1 * var(--space-4));  }

  .m-480-l-auto { margin-left: auto }
  .m-480-r-auto { margin-right: auto }
  .m-480-x-auto { margin-left: auto; margin-right: auto; }
  .m-480-y-auto { margin-top: auto; margin-bottom: auto; }

}
