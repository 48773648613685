/**
 * @define Icon
 * use strict
 */

.Icon {
  &--bigBlue {
    width: 2.875rem;
    margin-right: 0.5rem;
  }

  &--avatarPlaceholder {
    width: 7rem;
    margin-right: 0.5rem;
  }

  &--errorInfo {
    width: 0.75rem;
    margin-bottom: -0.1rem;
    margin-right: 0.3rem;
  }

  &--downArrow {
    margin: -4.5rem auto 1.8rem;
    display: block;
    opacity: 0.4;
    width: 1.5rem;
  }

  &--hamburger {
    width: 1.6rem;
  }
}
